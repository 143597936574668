/* @flow */

import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
import Auth from './components/Auth';

type Options = {
  baseUrl: string,
  bearer?: void,
};

function ApiError(message, code, data) {
  this.name = this.constructor.name;
  this.message = message;
  this.code = code;
  this.data = data;
}

function queryUrl(url, params) {
  const filteredParams = Object.keys(params).reduce((obj, key) => {
    if (typeof params[key] !== 'undefined') {
      obj[key] = params[key];
    }
    return obj;
  }, {});
  return url + (url.indexOf('?') === -1 ? '?' : '&') + queryString.stringify(filteredParams, { arrayFormat: 'bracket' });
}

// TODO better solution
function filterUndefined(params) {
  return Object.keys(params).reduce((obj, key) => {
    if (typeof params[key] !== 'undefined') {
      obj[key] = params[key];
    }
    return obj;
  }, {});
}
/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch({ baseUrl }: Options) {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    method: 'POST', // handy with GraphQL backends
    mode: baseUrl ? 'cors' : 'same-origin',
    // credentials: baseUrl ? 'include' : 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Slowly: 0,
    },
  };

  const customFetch = (url, options) => {
    const fullUrl = url.startsWith('/') ? `${baseUrl}${url}` : url;

    return fetch(options.qs ? queryUrl(fullUrl, options.qs) : fullUrl, {
      ...defaults,
      ...options,
      headers: filterUndefined({
        ...defaults.headers,
        ...(Auth.isLogged() ? { Authorization: `Bearer ${Auth.getTokenId()}` } : {}),
        ...(options && options.headers),
      }),
    })
      .then(async res => {
        if (res.status === 204) {
          return true;
        }

        const data = await res.json();

        if (res.ok) {
          if (res.headers.get('X-Pagination-Total-Count') !== null) {
            return {
              list: data,
              pagination: {
                totalCount: +res.headers.get('X-Pagination-Total-Count'),
                currentPage: +res.headers.get('X-Pagination-Current-Page'),
                pageCount: +res.headers.get('X-Pagination-Page-Count'),
                perPage: +res.headers.get('X-Pagination-Per-Page'),
                hasMore: +res.headers.get('X-Pagination-Current-Page') < +res.headers.get('X-Pagination-Page-Count'),
              },
            };
          }

          return data;
        }

        if (res.status === 422) {
          throw new ApiError('Data Validation Failed', res.status, data);
        }

        if (res.status === 401) {
          Auth.logout(true); // Works only for client side Universal cookie bug
          throw new ApiError('Session has expired', res.status, data);
        }

        throw new ApiError(data.message || 'Error', res.status, data);
      })
      .catch(err => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(err.message, err.code);
        }
      });
  };

  return (url, options) => customFetch(url, options);
}

export default createFetch;
