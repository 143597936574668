import Auth from '../components/Auth';

const routes = {
  path: '',

  children: [
    {
      path: '',
      action() {
        return { redirect: '/work-order' };
      },
    },

    {
      path: '/work-center/:from([\\d]{4}-[\\d]{2}-[\\d]{2})?',
      load: () => import(/* webpackChunkName: 'work-center' */ './work-center/calendar'),
    },

    {
      path: '/admin',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'admin' */ './admin'),
        },
        {
          path: '/user',
          load: () => import(/* webpackChunkName: 'admin' */ './admin/user'),
        },
        {
          path: '/customer',
          load: () => import(/* webpackChunkName: 'customer' */ './admin/customer'),
        },
        {
          path: '/salesman',
          load: () => import(/* webpackChunkName: 'salesman' */ './admin/salesman'),
        },
      ],
      action(context) {
        if (!Auth.can(Auth.RBAC_PERMISSION_VIEW_ADMIN_PANEL)) return { redirect: '/work-order' };
        return context.next();
      },
    },
    {
      path: '/ui',
      load: () => import(/* webpackChunkName: 'ui' */ './ui'),
    },
    {
      path: '/sign-in',
      load: () => import(/* webpackChunkName: 'sign-in' */ './sign-in'),
    },

    {
      path: '/forgot-password',
      load: () => import(/* webpackChunkName: 'forgot-password' */ './forgot-password'),
    },

    {
      path: '/restore-password/:token',
      load: () => import(/* webpackChunkName: 'restore-password' */ './restore-password'),
    },

    {
      path: '/assignee/:from([\\d]{4}-[\\d]{2}-[\\d]{2})?',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'assignee' */ './assignee/calendar'),
        },
        {
          path: '/:id([\\d]+)/:from([\\d]{4}-[\\d]{2}-[\\d]{2})?',
          children: [
            {
              path: '',
              load: () => import(/* webpackChunkName: 'assignee' */ './assignee/view'),
            },
          ],
        },
      ],
    },

    {
      path: '/work-order/:from([\\d]{4}-[\\d]{2}-[\\d]{2})?',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'work-order' */ './work-order/calendar'),
        },
        {
          path: '/create',
          load: () => import(/* webpackChunkName: 'work-order' */ './work-order/create'),
        },
        {
          path: '/:id([\\d]+)',
          children: [
            {
              path: '/:from([\\d]{4}-[\\d]{2}-[\\d]{2})?',
              load: () => import(/* webpackChunkName: 'work-order' */ './work-order/view'),
            },
            {
              path: '/edit',
              load: () => import(/* webpackChunkName: 'work-order' */ './work-order/update'),
            },
          ],
        },
      ],
    },

    {
      path: '/work-order-list',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'work-order-list' */ './work-order/list'),
        },
      ],
    },

    {
      path: '/job-number/:from([\\d]{4}-[\\d]{2}-[\\d]{2})?',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'job-number' */ './job-number/calendar'),
        },
        {
          path: '/:id([\\d]+)',
          children: [
            {
              path: '',
              load: () => import(/* webpackChunkName: 'job-number' */ './job-number/view'),
            },
            {
              path: '/edit',
              load: () => import(/* webpackChunkName: 'job-number' */ './job-number/update'),
            },
          ],
        },
      ],
    },

    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action(context) {
    try {
      if (Auth.isGuest() && (context.pathname !== Auth.getSignInPage() && context.pathname !== '/forgot-password' && !context.pathname.startsWith('/restore-password'))) {
        return { redirect: Auth.getSignInPage() };
      }

      const route = await context.next();

      route.title = `${route.title || 'Untitled Page'}`;
      route.description = route.description || '';

      return route;
    } catch (err) {
      if (err.code === 403 || err.code === 401) {
        return {
          redirect: Auth.getSignInPage(),
        };
      }
      throw err;
    }
  },
};

if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    /* eslint-disable-next-line global-require */
    action: require('./error').default,
  });
}

export default routes;
