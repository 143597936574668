import Cookies from 'universal-cookie';
import queryString from 'query-string';
import createFetch from '../createFetch';

// @flow
type Token = {
  token: string,
  expiration_time: string,
  expiration_in: number,
};

class Auth {
  static TOKEN_KEY: string = '_token';
  static USER_INFO_KEY: string = '_user_info';
  static cookies: Cookies;
  static fetch: createFetch;
  static timeout;

  static RBAC_ROLE_BASE_USER: string = 'base_user';
  static RBAC_ROLE_BASE_ADMIN: string = 'base_admin';

  static RBAC_ROLE_ADMIN: string = 'admin';
  static RBAC_ROLE_READER: string = 'reader';
  static RBAC_ROLE_WRITER: string = 'writer';

  static RBAC_PERMISSION_READ: string = 'read';
  static RBAC_PERMISSION_WRITE: string = 'write';
  static RBAC_PERMISSION_DELETE_USER: string = 'delete_user';
  static RBAC_PERMISSION_HAS_WORK_CENTER: string = 'has_work_center';
  static RBAC_PERMISSION_CHANGE_USER_PASSWORD: string = 'change_user_password';
  static RBAC_PERMISSION_VIEW_ADMIN_PANEL: string = 'view_admin_panel';
  static RBAC_PERMISSION_CREATE_CUSTOMER: string = 'create_customer';

  static can(permissionName) {
    if (Auth.isGuest()) {
      return false;
    }

    const user = Auth.getUser();
    const { permissions } = user;

    return Array.isArray(permissions) && permissions.includes(permissionName);
  }

  static async authorize(email: string, password: string) {
    const token = await this.fetch('/auth/token', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    });

    await this.tokenAuthorize(token);
  }

  static async signUpConfirm(confirmationHash) {
    const token = await this.fetch('/auth/confirm', {
      method: 'POST',
      body: JSON.stringify({
        confirmation_hash: confirmationHash,
      }),
    });

    await this.tokenAuthorize(token);
  }

  static async fetchUserData() {
    const user = await this.fetch('/user/me', {
      method: 'GET',
    });
    this.setUser(user);
  }

  static async tokenAuthorize(token) {
    this.setToken(token);

    await Auth.fetchUserData();
    this.triggerEvent('login');

    Auth.startAutoRefresh();
  }

  static logout(expire) {
    this.removeUser();
    this.removeToken();

    this.triggerEvent(expire ? 'expire' : 'logout');
  }

  static getSignInPage(backPage) {
    const page = '/sign-in';
    if (!backPage || backPage.substr(0, page.length) === page) {
      return page;
    }

    const query = `?${queryString.stringify({
      backPage,
    })}`;

    return `${page}${query}`;
  }

  static async refreshInfo() {
    const user = await this.fetch('/user/me', {
      method: 'GET',
    });
    this.setUser(user);

    this.triggerEvent('refresh-info');
  }

  static startAutoRefresh() {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    const pre = 10 * 1000;
    let expiration = new Date(token.expiration_time).getTime() - new Date().getTime();

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (expiration > 1000) {
      expiration = expiration < pre ? expiration : expiration - pre;
      this.timeout = setInterval(async () => {
        try {
          const data = await this.fetch('/auth/refresh', {
            method: 'POST',
          });
          this.setToken(data);

          this.triggerEvent('refresh-token');
        } catch (err) {
          this.logout(true);
        }
      }, expiration);
    } else {
      this.logout(true);
    }

    return true;
  }

  // User info
  static setUser(data) {
    return this.saveData(this.USER_INFO_KEY, data);
  }

  static getUser() {
    return this.getData(this.USER_INFO_KEY);
  }
  static getUserId(): number | null {
    const user = this.getUser();
    return user ? user.id : null;
  }

  static removeUser() {
    return this.removeData(this.USER_INFO_KEY);
  }

  // Tokens
  static getToken(): Token | false {
    return this.getData(this.TOKEN_KEY);
  }

  static getTokenId(): string | null {
    const token = this.getToken();
    return token ? token.token : null;
  }

  static removeToken() {
    return this.removeData(this.TOKEN_KEY);
  }

  static setToken(newToken: Token) {
    const token = { ...newToken };

    const date = new Date();
    date.setTime(date.getTime() + token.expiration_in * 1000);
    token.expiration_time = date.toISOString();

    return this.saveData(this.TOKEN_KEY, token);
  }

  static isLogged(): boolean {
    return !!this.getToken();
  }

  static isGuest(): boolean {
    return !this.getToken();
  }

  // Events
  static triggerEvent(detail) {
    if (typeof document !== 'undefined') {
      const event = new CustomEvent('onAuth', { detail });
      document.dispatchEvent(event);
    }
  }

  // Storage
  static getData(key) {
    const data = this.cookies.get(key);
    if (typeof data === 'undefined') {
      return null;
    } else if (typeof data === 'object') {
      return data;
    }
    try {
      return JSON.parse(data);
    } catch (e) {
      this.removeData(key);
      return null;
    }
  }

  static saveData(key, data) {
    return this.cookies.set(key, JSON.stringify(data), { path: '/' });
  }

  static removeData(key) {
    return this.cookies.remove(key, { path: '/' });
  }
}

export default Auth;
